import React from 'react'

import img2 from '../../images/clean.jpg'
import img3 from '../../images/easy-steps-to-rationalize-your-office-repair-amp-maintenance-cost-collars-connect.png'
import img4 from '../../images/constructionwork.webp'
import img5 from '../../images/Housekeeping.webp'
export default function Servives() {
  return (
   <>
   
   
   <div id='services' >
        <h1  className='text-green-600 font-bold text-4xl text-center max-md:mt-12 mt-8 '>Our services</h1>
      </div>
      <div className='flex flex-wrap  justify-center  max-md:-mt-4 my-8 gap-7 '>
      <a href='/cleaing' className='  h-48 '>
         <div className="flex justify-center items-center py-10">
             <div className="relative rounded-3xl overflow-hidden w-80 h-48 group">
              <img 
            src={img2} 
            className="rounded-3xl h-full w-full object-cover transition-transform duration-1000 group-hover:scale-110" 
            alt='Service'
               />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent rounded-3xl flex flex-col items-center justify-end p-6 transition-all duration-500 opacity-0 group-hover:opacity-100">
            <h3 className="font-bold text-4xl text-white">Cleaning</h3>
           </div>
           </div>
        </div>
      </a>
      <a href='/repairing' className='  h-48 '>
         <div className="flex justify-center items-center py-10">
             <div className="relative rounded-3xl overflow-hidden w-80 h-48 group">
              <img 
            src={img3} 
            className="rounded-3xl h-full w-full object-cover transition-transform duration-1000 group-hover:scale-110" 
            alt='Service'
               />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent rounded-3xl flex flex-col items-center justify-end p-6 transition-all duration-500 opacity-0 group-hover:opacity-100">
            <h3 className="font-bold text-4xl text-white">Maintenance</h3>
           </div>
           </div>
        </div>
      </a>
      <a href='/manpower'className='  h-48 '>
         <div className="flex justify-center items-center py-10">
             <div className="relative rounded-3xl overflow-hidden w-80 h-48 group">
              <img 
            src={img4} 
            className="rounded-3xl h-full w-full object-cover transition-transform duration-1000 group-hover:scale-110" 
            alt='Service'
               />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent rounded-3xl flex flex-col items-center justify-end p-6 transition-all duration-500 opacity-0 group-hover:opacity-100">
            <h3 className="font-bold text-3xl text-white">Manpower Supply </h3>
           </div>
           </div>
        </div>
      </a>
     
      </div>

   </>
  )
}
