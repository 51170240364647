import { createStore } from "redux";

const initialState = {
  Name: '',
  phone: '',
  email: '',
  address: '',
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, Name: action.payload };
    case 'SET_PHONE':
      return { ...state, phone: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_ADDRESS':
      return { ...state, address: action.payload };
    case 'RESET_FORM':
      return initialState;
    default:
      return state;
  }
};

const store = createStore(contactReducer);

export default store;
