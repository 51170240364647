import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import toast from "react-hot-toast";

const Contact = () => {
  const dispatch = useDispatch();
  const { Name, phone, email, address } = useSelector(state => state);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleContact = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      const { data } = await axios.post(
        "https://backend.shaniq.in/api/c2/booking/send",
        { Name, phone, email, address },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      toast.success(data.message);
      dispatch({ type: 'RESET_FORM' });
      navigate("/");
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      toast.error(error.response.data.message);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div >
      <h1 className='text-green-600 font-bold text-4xl text-center max-md:mt-8 mt-20 '>Contact Us</h1>
      <section className="flex flex-col md:flex-row justify-center lg:mt-6 p-4">
        <div className="flex flex-col items-center justify-center p-4 md:w-1/2">
          <a href='https://maps.app.goo.gl/QpJVVPh5XfE2dWeS9' target='_blank' className="flex items-center mb-4">
            <FaLocationDot className='text-green-500 mr-2' />
            <p className='text-center text-xl'>Plot No 4255, Gita Khetra, Pandara, Bhubaneswar, 751025</p>
          </a>
          <a href="mailto:support@shaniq.in" className="flex items-center mb-4 text-xl">
            <IoIosMail className='text-green-500 mr-2' />
            support@shaniq.in
          </a>
          <a href="tel:+91 7848073877" className="flex items-center mb-4 text-xl">
            <MdCall className='text-green-500 mr-2' />
            +91 7848073877
          </a>
          <a href="https://api.whatsapp.com/send?phone=917848073877&amp;forceIntent=true&amp;load=loadInIOSExternalSafari" target="_blank" className="flex items-center mb-4 text-xl">
            <IoLogoWhatsapp className='text-green-500 mr-2' />
            +91 7848073877
          </a>
          <p className='text-center text-xl'>We are available 24x7</p>
        </div>




        <div  className="flex flex-col items-center justify-center p-4 md:w-1/2 " >
          <div className="mb-4 max-md:mt-7" >
            <h2 className="text-blue-600 font-bold text-2xl text-center ">For Enquiries Now</h2>
          </div>
          <div className="space-y-4 w-full max-w-md mt-4">
            <input
              type='text'
              placeholder='Enter your Name'
              value={Name}
              onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value })}
              className='w-full border p-2 rounded'
            />
            <input
              type='text'
              placeholder='Enter your Number'
              value={phone}
              onChange={(e) => dispatch({ type: 'SET_PHONE', payload: e.target.value })}
              className='w-full border p-2 rounded'
            />
            <input
              type='text'
              placeholder='Enter your Email'
              value={email}
              onChange={(e) => dispatch({ type: 'SET_EMAIL', payload: e.target.value })}
              className='w-full border p-2 rounded'
            />
            <textarea
              placeholder='Enter your Address'
              value={address}
              onChange={(e) => dispatch({ type: 'SET_ADDRESS', payload: e.target.value })}
              className='w-full border p-2 rounded'
              rows="4"
            />
           <button
               type='submit'
             onClick={handleContact}
                   disabled={loading} 
                className={`w-full bg-green-500 text-white p-2 rounded max-md:mx-2 hover:bg-green-500 active:bg-green-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
  {loading ? 'Submitting...' : 'Submit'}
</button>
          </div>
        </div>
      </section>
    </div>
  )
};
export default Contact;
