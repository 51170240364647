import React, { Suspense, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import images from '../Data/view image';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import img from '../../images/about.webp';

  
  
export default function ViewDirectService() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const sliderLeft = () => {
    const slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
    setScrollPosition(slider.scrollLeft);
  };

  const sliderRight = () => {
    const slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
    setScrollPosition(slider.scrollLeft);
  };

  return (
    <>
      <h1 className="text-green-600 font-bold text-4xl text-center max-md:mt-20 mt-24">
        Photo Gallery
      </h1>
      <div className="relative flex items-center p-14 max-md:p-1">
        <MdChevronLeft
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={sliderLeft}
          size={50}
        />
        <div
          id="slider"
          className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth"
        >
          {images.map((item, index) => (
            <img
              key={index}
              className="w-[300px] h-[200px] inline-block p-2 cursor-pointer hover:scale-95 ease-in-out duration-300 object-cover rounded-3xl 
                        max-md:w-[270px]"
              src={item.img}
              alt={`Slide ${index}`}
            />
          ))}
        </div>
        <MdChevronRight
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={sliderRight}
          size={50}
        />
      </div>

      <div className="p-14 max-md:p-2 w-full flex justify-center">
        <img
          src={img}
          className="w-auto object-cover md:mt-3 sm:mt-3 max-md:mt-3 translate-y-6 lg:-px-10"
          alt="network error"
        />
      </div>

      
    </>
  );
}
