const TestinomialData =[
    { 
        "name":"Saroj kumar sahoo",
        "img":"image/s round logo.png",
        "review":"Sanique made the entire process of getting my home appliances repaired so easy. The technician arrived on time, fixed the issue quickly, and was very professional. Highly recommended for anyone looking for reliable home services!"
       
    },
    { 
        "name":"Laxmidhara sahoo",
        "img":"image/Lround.png",
        "review":"We hired Sanique for our office cleaning, and the results were fantastic! The team was thorough, friendly, and left our workspace spotless. Their customer service and attention to detail really stood out. Will definitely use them again!"
       
    },
    { 
        "name":"Rajesh kumar",
        "img":"image/Rlogo.png",
        "review":"I needed manpower for an urgent office renovation, and Sanique delivered! The professionals they provided were skilled and worked with minimal supervision. Excellent service!"
       
    },
    { 
        "name":"Swayam parija",
        "img":"image/sroundlogo.png",
        "review":"The deep cleaning service from Sanique was a game-changer for our home. The team was professional and used eco-friendly products, which was a huge plus for us. Our house has never looked better!"
       
    }, { 
        "name":"Prakash Rath",
        "img":"image/proundlogo.png",
        "review":"It provides very good service and it quick response my problem slove within the 24hr Thank you shaniq"
       
    }
   
]
export default TestinomialData;