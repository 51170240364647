import React from 'react'
import TestinomialData from './TestinomailData'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonial() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3, 
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <>
      <div>
        <h1 className='text-green-600 font-bold text-4xl text-center max-md:mt-16 mt-10 '>Testimonials</h1> 
      </div>
      <div className='w-full m-auto bg-green-400 max-md:px-2 max-md:w-full max-md:h-[350px] '>
        <div className='mt-8 p-8 max-md:p-4'>
          <Slider {...settings}>  
            {TestinomialData.map((i) => (
              <div key={i.name} className='bg-white h-[290px] text-black rounded-xl max-md:h-[300px] max-md:w-16  '>
                <div className='h-30 w-full rounded-t-xl flex justify-center items-center '>
                  <img src={i.img} alt='network error' className='h-28 p-2 w-28 rounded-full max-md:' />
                </div>
                <div className='flex flex-col justify-center items-center gap-4 p-2 max-md:-my-3'>
                  <p className='text-xl font-semibold text-black max-md:text-lg max-md:my-3'>{i.name}</p>
                  <p className='text-black text-center max-md:-my-7'>" {i.review} "</p>
                </div>
              </div>
            ))} 
          </Slider>   
        </div>
      </div>
    </>
  )
}