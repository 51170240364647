import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import toast from "react-hot-toast";

export default function CleaningCatagory({ image, name, description }) {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { Name, phone, email, address } = useSelector(state => state);


  const navigate = useNavigate();

  const handleContact = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      const { data } = await axios.post(
        "https://backend.shaniq.in/api/c2/booking/send",
        { Name, phone, email, address },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      toast.success(data.message);
      dispatch({ type: 'RESET_FORM' });
        navigate("/cleaing");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false); 
    }
  };


  const handleBookNowClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className='w-72 my-4 bg-zinc-500 rounded-3xl border-4 p-4 max-sm:p-2 max-sm:w-44 flex flex-col justify-between'>
      <img 
        className='h-40 rounded-3xl shadow-2xl max-sm:h-28 border-4'
        src={image}
        alt={name}
         loading="lazy"
      />
      <h1 className='text-center max-sm:text-lg font-bold text-xl text-white'>{name}</h1>
      <p className='text-white max-sm:text-sm mt-2 '>- {description}</p>
      <span className='flex justify-center max-sm:justify-center gap-3 mt-auto'>
        <button 
         
          className='inline-block px-1 py-2 text-xl font-semibold text-white bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 active:bg-blue-300 active:shadow-inner transform active:translate-y-1 transition-all duration-150 max-sm:text-xs mt-2'
          onClick={handleBookNowClick}
        >
          Book Now
        </button>
        <a href="tel:91 8908042477" className=' md:hidden inline-block px-1 py-2 text-xl font-semibold text-white bg-green-700 rounded-lg shadow-md hover:bg-green-700 active:bg-green-300 active:shadow-inner transform active:translate-y-1 transition-all duration-150 max-sm:text-xs mt-2'>
          Call Now
        </a>
        <a href="https://api.whatsapp.com/send?phone=917848073877&amp;forceIntent=true&amp;load=loadInIOSExternalSafari" target="_blank" className='max-md:hidden inline-block px-1 py-2  font-semibold text-white bg-green-700 rounded-lg shadow-md hover:bg-green-700 active:bg-green-300 active:shadow-inner transform active:translate-y-1 transition-all duration-150 max-sm:text-xs mt-2'>
        whatsup Now
        </a>
      </span>

      {showForm && (
        <div    className="fixed  inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
            <button
              onClick={handleCloseForm}
              className="absolute top-2 right-2 text-5xl text-gray-600 hover:text-black"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold text-center mb-4">Book Now</h2>
            <form className="space-y-4">
              <input
                type='text'
                placeholder='Enter your Name'
                value={Name}
                onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value })}
                className='w-full border p-2 rounded'
              />
              <input
                type='text'
                placeholder='Enter your Number'
                value={phone}
                onChange={(e) => dispatch({ type: 'SET_PHONE', payload: e.target.value })}
                className='w-full border p-2 rounded'
              />
              <input
                type='text'
                placeholder='Enter your Email'
                value={email}
                onChange={(e) => dispatch({ type: 'SET_EMAIL', payload: e.target.value })}
                className='w-full border p-2 rounded'
              />
              <textarea
                placeholder='Enter your Address'
                value={address}
                onChange={(e) => dispatch({ type: 'SET_ADDRESS', payload: e.target.value })}
                className='w-full border p-2 rounded'
                rows="4"
              />
             <button
               type='submit'
             onClick={handleContact}
                   disabled={loading} 
                className={`w-full bg-blue-500 text-white p-2 rounded max-md:mx-2 hover:bg-blue-500 active:bg-blue-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                 {loading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
