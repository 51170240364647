import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Navbar from "./Componet/navbar/Navbar";
import Home from "./Componet/Pages/Home";
import WorkHead from "./Componet/WorkHead/WorkHead";
import CleaningCategoryrender from "./Componet/Catagory/CleaningCategoryrender";
import Reparingworkcategoryrender from "./Componet/Catagory/reparingworkcategoryrender";
import Manpowercatogoryrender from "./Componet/Catagory/manpowercatogoryrender";
import Workrender from "./Componet/WorkHead/Workrender";
import Reparingworkrender from "./Componet/WorkHead/reparingworkrender";
import Manpowerworkrender from "./Componet/WorkHead/manpowerworkrender";
import ViewDirectService from "./Componet/Pages/ViewDirectService";
import Testimonial from "./Componet/Testimonial/Testimonial";
import Contact from "./Componet/Contact/Contact";
import Contactinfo from "./Componet/Contactinfo/Contactinfo";
import Aboutus from "./Componet/Aboutus";
import { Toaster } from "react-hot-toast";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Navbar />
          <Contactinfo /> 
         
        </>
      ),
      children: [
        { index: true, element: <Home /> },
        { path: "/about", element: <Aboutus /> },
        { path: "/cleaing", element: <Workrender /> },
        { path: "/repairing", element: <Reparingworkrender /> },
        { path: "/manpower", element: <Manpowerworkrender /> },
        { path: "/testimonial", element: <Testimonial /> },
        { path: "/service", element: <ViewDirectService /> },
        { path: "/contact", element: <Contact /> },
        
        
     
        {
          path: "/work/:workId",
          element: <CleaningCategoryrender />,
          children: [
            { path: ":categoryId", element: <WorkHead /> },
          ],
        },
        {
          path: "/repair/:repairId",
          element: <Reparingworkcategoryrender />,
          children: [
            { path: ":categoryId", element: <WorkHead /> },
          ],
        },
        {
          path: "/manpower/:manpowerId",
          element: <Manpowercatogoryrender />,
          children: [
            { path: ":categoryId", element: <WorkHead /> },
          ],
        }
      ],
      
    },
    
  ]);


  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

export default App;
