import React from 'react';
import img from '../../images/img.png';

export default function Contactinfo() {
  return (
    <>
      <footer className="bg-lime-200 text-black w-full mt-20 absolute ">
        <div className=" relative flex flex-col items-center md:items-start   ">
         <a href='\'>
           <img 
            className="h-56 w-auto object-fill 
            lg:absolute md:mx-72 lg:mx-20
            mx-auto lg:mt-4 lg:ml-64
            max-md:mt-6
            md:-mt-2
            sm:mt-6"
            src={img}
            alt="Shaniq"
          />
          </a>   
          <h1
            className="text-black text-2xl  absolute max-md:mt-56 max-md:text-xl 
            max-md:ml-4  
            lg:my-52 font-bold lg:ml-52  md:my-48
            md:ml-56
            "
          >
            Venewone Solutions Pvt. Ltd.
          </h1>
        </div>

        <div className="container flex flex-col md:flex-row justify-center md:justify-end lg:-mx-32 md:-mx-4 gap-8 md:gap-24 text-center md:text-left py-20">
          <div>
            <h3 className="font-bold text-lg">Quick Links</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="contact" className="hover:underline">Contact Us</a></li>
              <li><a href="https://docs.google.com/forms/d/e/1FAIpQLScMn9QLXSzwyuGXIFWH9-eujCQIlIEZuLhSVxvQR2OY-xkfPw/viewform" target='_'  className="hover:underline">Careers</a></li>
              <li><a href="https://www.youtube.com/@ShaniQ-c9g" target='_'           className="hover:underline">Youtube</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg">Important Links</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="#" className="hover:underline">Home</a></li>
              <li><a href="#services" className="hover:underline">Services</a></li>
              <li><a href="about" className="hover:underline">About Us</a></li>
            </ul>
          </div>
          <div className="mt-8 md:mt-0">
            <h1 className="text-center md:text-left text-xl font-bold">Address</h1>
            <p >
              Plot No 4255, Gita Khetra,
              <br />
              Pandara, Bhubaneswar, 751025
            </p>
          </div>
        </div>

        <div className="mt-8 text-center text-sm border-t border-gray-700 pt-4">
          <p>Copyright © 2024 | ShaniQ</p>
        </div>
      </footer>
    </>
  );
}
