
import React from 'react';
import WorkHead from './WorkHead';
import { manpowerservices } from '../Data/Data';
import { NavLink } from 'react-router-dom';

export default function manpowerworkrender() {
  return (
    <>
      <div className='mt-24'>
        <h1 className='text-center font-bold text-4xl '>Manpower Supply</h1>
      </div>

      <div className='flex flex-wrap justify-center bg-lime-300 lg:gap-6 mt-8 lg:p-14 md:p-5 md:gap-8 md:justify-center'>
        {manpowerservices.map((manpower, index) => (
          <div key={index} className='flex flex-col items-center w-full sm:w-1/2 md:w-1/2 lg:w-1/4 max-md:px-14 max-md:p-4 max-[320px]:px-5'>
            <NavLink to={`/manpower/${manpower.id}`} className='w-full'>
              <WorkHead
                id={manpower.id}
                img={manpower.img}
                desc={manpower.desc}
                view={manpower.view}
                name={manpower.name}
              />
            </NavLink>
          </div>
        ))}
      </div>
     
    </>
  );
}
