import React from 'react'
import { Helmet } from 'react-helmet';
export default function Aboutus() {
  return (
      <>
  <Helmet>
        <title>About Us </title>
        <meta name="description" content="Learn more about ShaniQ - Our mission, values, and the quality maintenance, cleaning, and manpower services we provide." />
      </Helmet>
    <div className='mt-28  '>
      <h1 className='text-2xl font-bold mx-5'>About us </h1>
      <p className='ml-5 mt-4'>-Venewone Solution Pvt Ltd is a leading provider of comprehensive outsourcing services, specializing in manpower supply, cleaning, and maintenance solutions.Established with a commitment to excellence, we pride
ourselves on delivering tailored solutions that meet the
diverse needs of our clients across various industries.</p>
<h1 className='text-2xl font-bold mx-5'>Vision </h1>
      <p className='ml-5 mt-4'>-To be the leading provider of outsourcing services
recognized for our innovation, integrity, and
commitment to exceptional service. We strive to set
new standards in the industry and contribute
positively to the success of our clients and the
communities we serve.</p>
<h1 className='text-2xl font-bold mx-5'>Mission </h1>
      <p className='ml-5 mt-4'>-Our mission is
to enhance operational efficiency and ensure
a pristine environment for businesses and
organizations. We are dedicated to offering
top-notch services that are reliable, costeffective, and designed to exceed
expectations</p>
<h1 className='text-2xl font-bold mx-5'>Vision </h1>
      <p className='ml-5 mt-4'>-To be the leading provider of outsourcing services
recognized for our innovation, integrity, and
commitment to exceptional service. We strive to set
new standards in the industry and contribute
positively to the success of our clients and the
communities we serve.</p>

<ul className='text-2xl font-bold mx-5  mt-4'>Our Services</ul>
    <li className='ml-5 mt-4' >Manpower Supply: We connect businesses with the right talent,
offering skilled and unskilled workers for temporary,
permanent, and project-based needs. Our recruitment process
ensures that we provide candidates who are not only qualified
but also align with your company’s culture and requirements.</li>
<li className='ml-5 mt-4'>Cleaning Services: Our cleaning solutions are designed to
maintain a clean and healthy environment. From daily
maintenance to deep cleaning, our professional team uses
advanced techniques and eco-friendly products to ensure your
premises are spotless and welcoming.</li>
<li className='ml-5 mt-4'>Maintenance Services: We offer comprehensive maintenance
services to keep your facilities and equipment in optimal
condition. Our team is equipped to handle routine upkeep,
emergency repairs, and preventive maintenance, helping you
avoid costly disruptions and extend the lifespan of your assets.</li>

<ul className='text-2xl font-bold mx-5 '>Why Choose us?</ul>
    <li className='ml-5 mt-4' >Expert Team: Our team consists of highly trained professionals with extensive
experience in their respective fields. We invest in ongoing training and
development to ensure our staff remains at the forefront of industry best
practices.
</li>
<li className='ml-5 mt-4'>Customized Solutions: We understand that every business is unique, and we
tailor our services to fit your specific requirements. Our flexible approach
ensures that you receive the most effective solutions for your needs.</li>
<li className='ml-5 mt-4'>Proven Track Record: Our success is reflected in the satisfaction and loyalty of
our clients. We have consistently delivered high-quality services and have
earned a reputation for reliability and excellence.</li>
    
<h1 className='text-2xl font-bold mx-5'>Our Goals </h1>
      <p className='ml-5 mt-4'>-Our goal is to set the
industry standard for excellence in outsourcing by
becoming the most trusted partner for businesses
seeking comprehensive manpower, cleaning, and
maintenance services. We strive to be a catalyst for
our clients' success by offering innovative, reliable,
and cost-effective solutions that address their unique
challenges and help them achieve their operational
and strategic objectives.</p>
    
    <div className=' flex justify-end mx-20 mt-10' >
      <a href='\'>
      <button type='submit' className='border-4 px-7 text-xl border-black text-black'>Back</button>  
      </a>
   
    </div>
   
    </div>
    </>
  )
}
