import React from 'react';

export default function WorkHead({ img, name, desc, view, id }) {
  return (
    <div className='flex flex-col md:flex-row bg-rose-200 p-2 rounded-2xl h-auto md:h-36 max-md:items-center shadow-xl '>
      <section className='flex-shrink-0  w-40 h-32 max-md:items-center max-md:w-60 '>
        <img src={img} className='w-full h-full object-cover border-2 rounded-3xl shadow-xl ' alt='Work' />
      </section>
      <section className='flex-1 flex flex-col justify-center mx-3 my-2'>
        <div className='text-center md:text-left '>
          <h1 className='font-bold text-black   text-[15px] '>{name}</h1>
          <p className='text-black text-[13px] '>{desc}</p>
          <h2 className='text-black text-[13px]'>{view}</h2>
        </div>
      </section>
    </div>
  );
}