const images = [
    {
      id: "1",
      name: "AC Repairing",
      img: "image/AC.jpg",
    },
    {
      id: "2",
      name: "Sofa Cleaning",
      img: "image/sofa.webp",
    },
    {
      id: "3",
      name: "Bathroom Cleaning",
      img: "image/bathroom-cleaning.webp",
    },
    {
      id: "4",
      name: "Water Purifier Repair",
      img: "image/water-purifier.webp",
    },
    {
      id: "5",
      name: "Refrigerator Repair",
      img: "image/Refrigerator-Repair-1.jpg",
    },
    {
      id: "6",
      name: "TV Repair",
      img: "image/tv-repair.jpg",
    },
    {
      id: "7",
      name: "Deep Cleaning",
      img: "image/deep-cleaning.webp",
    },
    {
      id: "8",
      name: "Electrical Services",
      img: "image/electrical.webp",
    },
    {
      id: "9",
      name: "House Wiring",
      img: "image/house-wiring-500x500.webp",
    },
    {
      id: "10",
      name: "Carpenters",
      img: "image/Carpenters.webp",
    },
  ];
  
  export default images;
  