import React from 'react';
import { useParams } from 'react-router-dom';
import {cleanData} from '../../Componet/Data/Data';
import CleaningCatagory from '../Catagory/CleaningCatagory';

export default function CleaningCategoryrender() {
  const { workId } = useParams();
  const work = cleanData.find((item) => item.id === workId);

  if (!work) {
    return <div>Work not found</div>;
  }

  return (
   
             <div className='flex  w-full  flex-wrap  gap-2 justify-center  mt-28 
             max-md:mt-20  max-sm:justify-center max-sm:py-2 
              '>
      {work.category.map((cat) => (
        <CleaningCatagory
          key={cat.id}
          image={cat.image}
          name={cat.name}
          description={cat.description}
        />
      ))}
    </div>
    
   
  );
}




















// import React from 'react';
// import { useParams } from 'react-router-dom';
// import Data from '../../Componet/Data/Data';
// import CleaningCatagory from '../Catagory/CleaningCatagory';

// export default function CleaningCategoryrender() {
//   const { workId } = useParams();
//   const work = Data.find((item) => item.id === workId);

//   if (!work) {
//     return <div>Work not found</div>;
//   }

//   return (
   
//              <div className='flex  w-full    flex-wrap  gap-4 justify-center my-6 max-sm:justify-start max-md:py-2 
//               '>
//       {work.category.map((cat) => (
//         <CleaningCatagory
//           key={cat.id}
//           image={cat.image}
//           name={cat.name}
//           price={cat.price}
//           description={cat.description}
//         />
//       ))}
//     </div>
    
   
//   );
// }