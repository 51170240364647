import React from 'react';
export default function Video() {
    return (
      <div className="flex justify-center w-full mt-16">
        <iframe
          src="https://www.youtube.com/embed/gi2nKmZN3g8"
          title="YouTube video player"
          width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="sm:h-[100px] md:h-[400px] lg:h-[600px] max-md:h-[400px] max-md:w-full  w-11/12"
        />
      </div>
    );
  }
  
