import React from "react";
import Slider from "react-slick";

export default function AutoSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2000, 
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const data = [
    {
      img: "image/sliderthree copy.jpg",
    },
    {
      img: "image/maintenacesliders.png",
    },
    {
      img: "image/mapowersupplysliders.jpg",
  
    },
  ];

  return (
    <div className="slider-container lg:mt-28 lg:px-32  max-md:mt-16 max-md:px-2 md:mt-24">
      <Slider {...settings}>
        {data.map((item, index) => (
          <div key={index} className="p-2">
            <img
              src={item.img}
              alt={`slide-${index}`}
              className="rounded-md lg:h-96 md:h-56 sm:h-56 max-md:h-56 object-cover w-full "
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
