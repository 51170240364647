export const cleanData =[
    {
        "id": "Residential Cleaning",
        "img": "image/Residential_clean.jpg",
        "name": "Residential Cleaning",
        "desc": "All type of standard and deep Cleaning",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/Standardcleaning.webp",
            "name": "Standard Cleaning",
          
            "description": " We will doing Dusting, Vacuuming Mopping  ,Surface Cleaning"
          },
          {
            "id": 2,
            "image": "/image/house.png",
            "name": "Deep Cleaning",
            "description": "Detailed cleaning of kitchens and bathrooms .Baseboards and door frames.Inside cabinets and appliances"
          },
          {
            "id": 3,
            "image": "/image/moveinoutclean.jpg",
            "name": "Move-In/Move-Out Cleaning",
            "description": "Full property cleaning before or after a move.Carpet and floor cleaning"
          },
          {
            "id": 4,
            "image": "/image/POST-CONSTRUCTION-CLEANING.jpg",
            "name": "Post-Construction Cleaning",
            "price": 123,
            "description": "Removal of construction dust and debris .Cleaning of windows, walls, and floors."
          }
        ]
      },
      {
        "id": "Commercial Cleaning",
        "img": "image/commercial cleaninng.webp",
        "name": "Commercial Cleaning",
        "desc": "All type of office and industrial Cleaning",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/officecleaning (1).jpg",
            "name": "Office Cleaning",
            "price": 123,
            "description": "Daily cleaning of workspaces.Restroom sanitation.Trash removal"
          },
          {
            "id": 2,
            "image": "/image/Grocery-store-cleaning (1).jpg",
            "name": "Retail Cleaning",
            "price": 123,
            "description": "Storefront cleaning.Floor polishing.Window cleaning"
          },
          {
            "id": 3,
            "image": "/image/Industrial_Cleaning.jpg",
            "name": "Industrial Cleaning",
            "price": 123,
            "description": "Warehouse cleaning.Equipment and machinery cleaning.Hazardous waste removal"
          },
          {
            "id": 4,
            "image": "/image/education-cleaing.webp",
            "name": "Educational Facility Cleaning",
            "description": "Classroom and common area cleaning.Disinfection of high-touch surfaces.Restroom cleaning"
          }
        ]
      },
      {
        "id": "Specialized Cleaning",
        "img": "image/specialiezcleaing.jpg",
        "name": "Specialized Cleaning",
        "desc": "All type of window and air dust cleaning",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/carpetcleaing.jpg",
            "name": "Carpet & Upholstery Cleaning",
            "price": 123,
            "description": "Stain removal.Steam cleaning.Steam cleaning"
          },
          {
            "id": 2,
            "image": "/image/windowcleaing.jpg",
            "name": "Window Cleaning",
            "price": 123,
            "description": "Interior and exterior window washing.Screen and track cleaning"
          },
          {
            "id": 3,
            "image": "/image/pressurewasing.jpg",
            "name": "Pressure Washing",
            "price": 123,
            "description": "Driveways and sidewalks.Exterior walls.Deck and patio cleaning"
          },
          {
            "id": 4,
            "image": "/image/airdustcleaing.jpg",
            "name": "Air Duct Cleaning",
            "price": 123,
            "description": "HVAC system cleaning.Filter replacement.Mold and mildew removal"
          }
        ]
      },
      {
        "id": "Event Cleaning",
        "img": "image/eventclean.jpg",
        "name": "Event Cleaning",
        "desc": "All type of pre and post cleaning ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/preeventcleaing (1).jpg",
            "name": "Pre-Event Cleaning",
            "price": 123,
            "description": "Venue preparation.Setup assistance"
          },
          {
            "id": 2,
            "image": "/image/postevent.jpg",
            "name": "Post-Event Cleaning",
            "price": 123,
            "description": "Trash and debris removal.Venue restoration to original state"
          }
        ]
      },
      {
        "id": "Property maintenance",
        "img": "image/propetymaking.jpg",
        "name": "Property Maintenance Cleaning ",
        "desc": "All type of seasonal and emergency cleaning ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/house.png",
            "name": "Seasonal Cleaning",
            "price": 123,
            "description": "Spring and fall cleaning.Gutter cleaning.Garden and yard cleanup"
          },
          {
            "id": 2,
            "image": "/image/emergency.jpg",
            "name": "Emergency Cleaning",
            "price": 123,
            "description": "Flood or fire damage cleanup.Mold and mildew remediation"
          }
        ]
      },
      {
        "id": "Healthcare",
        "img": "image/hospitalmaking.jpg",
        "name": "Healthcare Facility Cleaning",
        "desc": "All type of clinic and hospital  cleaning ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/hospitalcleaing (1).jpg",
            "name": "Hospital Cleaning",
            "price": 123,
            "description": "Operating room sanitation.Patient room cleaning"
          },
          {
            "id": 2,
            "image": "/image/cliniccleaing.jpg",
            "name": "Clinic Cleaning",
            "description": "Waiting room and examination room cleaning.Disinfection of medical equipment"
          }
        ]
      },
      {
        "id": "Hospitality",
        "img": "image/hospitality_cleaning.webp",
        "name": "Hospitality Cleaning",
        "desc": "All type of hotel and common room cleaning  ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/hotel-room-cleaning-services (1).jpg",
            "name": "Hotel Room Cleaning",
            "price": 123,
            "description": "Bed making and linen change .Bathroom sanitization .Dusting and vacuuming"
          },
          {
            "id": 2,
            "image": "/image/Common_Area_Cleaning.jpg",
            "name": "Common Area Cleaning",
            "description": "Lobby and reception area cleaning .Elevator and hallway maintenance"
          },
          {
            "id": 3,
            "image": "/image/conference (1).jpg",
            "name": "Conference Room Cleaning",
            "description": "Pre- and post-event cleaning .AV equipment cleaning"
          },
          {
            "id": 4,
            "image": "/image/spac.jpg",
            "name": "Spa and Wellness Center Cleaning",
            "description": "Treatment room sanitation.Pool and sauna cleaning"
          }
        ]
      },
      {
        "id": "Specialty Surface",
        "img": "image/surface-cleaner-pros-cons.jpg",
        "name": "Specialty Surface Cleaning",
        "desc": "All type of marble and tiles cleaning ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/Marble-Floors-cleaing (1).jpg",
            "name": "Marble and Stone Cleaning",
            "price": 123,
            "description": "Polishing and sealing.Stain removal"
          },
          {
            "id": 2,
            "image": "/image/wooden-floors-celan.webp",
            "name": "Wood Floor Cleaning",
            "description": "Deep cleaning and refinishing .Scratch removal"
          },
          {
            "id": 3,
            "image": "/image/tilesand marble (1).jpg",
            "name": "Tile and Grout Cleaning",
            "description": "Grout line cleaning .Sealing and stain protection"
          }
        ]
      },
      {
        "id": "Food Service",
        "img": "image/foodservices.jpg",
        "name": "Food Service Cleaning",
        "desc": "All type of Restaurant and bar  cleaning ",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/restaurant+cleaning.jpg",
            "name": "Restaurant Cleaning",
            "price": 123,
            "description": "Kitchen and food prep area cleaning.Dining area sanitation"
          },
          {
            "id": 2,
            "image": "/image/cafeterial.webp",
            "name": "Cafeteria Cleaning",
            "description": "Counter and table cleaning.Floor care"
          },
          {
            "id": 3,
            "image": "/image/barcleing.jpg",
            "name": "Bar Cleaning",
            "description": "Bar counter and equipment cleaning .Glassware and utensil cleaning"
          }
        ]
      },
      {
        "id": "Sensitive",
        "img": "image/sensitiveenviroment.jpg",
        "name": "Sensitive Environment Cleaning",
        "desc": "All type of classroom and Laboratory cleaning",
        "view": "Click to see details",
        "category": [
          {
            "id": 1,
            "image": "/image/classroom cleaing.webp",
            "name": "Classroom Cleaning",
            "price": 123,
            "description": "Dust and particle control .Specialized cleaning protocols for sterile environments"
          },
          {
            "id": 2,
            "image": "/image/labcleaing.jpg",
            "name": "Laboratory Cleaning",
            "description": "Equipment and surface sterilization.Chemical spill cleanup"
          },
          {
            "id": 3,
            "image": "/image/pharmacetial.jpg",
            "name": "Pharmaceutical Facility Cleaning",
            "description": "Contamination control. Disinfection of production areas"
          } 
        ]
      }
]

















export const reparingData =[
  {
    "id": "Residential Property Maintenance",
    "img": "image/Residentaimaintence.jpg",
    "name": "Residential Property Maintenance",
    "desc": "All type of Residential Property Maintenance",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/regular-home-maintenance (1).jpg",
        "name": "Routine Home Maintenance",
        "price": 321,
        "description": "Preventative checks for electrical, plumbing, and HVAC systems.Seasonal maintenance tasks (e.g., gutter cleaning, pressure washing)"
      },
      {
        "id": 2,
        "image": "/image/regular-home-maintenance (1).jpg",
        "name": "Appliance Repairs",
        "price": 321,
        "description": "Fixing or replacing malfunctioning home appliances (refrigerators, ovens, washing machines)"
      },
      {
        "id": 3,
        "image": "/image/regular-home-maintenance (1).jpg",
        "name": "Interior & Exterior Repairs",
        "price": 321,
        "description": "Painting, drywall patching, and minor carpentry repairs.Deck and patio repair and maintenance"
      },
      {
        "id": 4,
        "image": "/image/regular-home-maintenance (1).jpg",
        "name": "Landscaping & Yard Maintenance",
        "price": 321,
        "description": "Lawn care, trimming, and seasonal planting.Snow removal and yard cleanup"
      }
    ]
  },
  {
    "id": "Commercial Property Maintenance",
    "img": "image/commercial-property-maintenance.jpg",
    "name": "Commercial Property Maintenance",
    "desc": "All type of Commercial Property Maintenance\n",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/buldingmaintenace (1).jpg",
        "name": "Facility Repairs",
        "price": 123,
        "description": "Electrical and plumbing fixes for office buildings, retail stores, and other commercial properties .HVAC system maintenance and repairs"
      },
      {
        "id": 2,
        "image":  "/image/buldingmaintenace (1).jpg",
        "name": "Building Exterior Maintenance",
        "price": 123,
        "description": "Window cleaning and facade upkeep.Pressure washing and parking lot maintenance"
      },
      {
        "id": 3,
        "image": "/image/buldingmaintenace (1).jpg",
        "name": "Preventative Maintenance",
        "price": 123,
        "description": "Scheduled checks for fire safety systems, elevators, and electrical equipment.Routine inspections and repair scheduling"
      },
      {
        "id": 4,
        "image":  "/image/buldingmaintenace (1).jpg",
        "name": "Commercial Appliance Maintenance",
        "price": 123,
        "description": "Servicing of industrial refrigerators, kitchen equipment, and electronics"
      }
    ]
  },
  {
    "id": " Office Maintenance",
    "img": "image/commercial-property-maintenance.jpg",
    "name": " Office Maintenance",
    "desc": "All type of  Office Maintenance",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/officemaintain.jpg",
        "name": "Office Equipment Repair",
        "price": 123,
        "description": "IT hardware repair and maintenance.Desk, chair, and other office furniture repairs"
      },
      {
        "id": 2,
        "image": "/image/officemaintain.jpg",
        "name": "General Facility Maintenance",
        "price": 123,
        "description": "Restroom cleaning, plumbing, and electrical work.HVAC and lighting system management"
      }
    ]
  },
  {
    "id": "Industrial Property Maintenance",
    "img": "image/indurstyproperty (1).jpg",
    "name": "Industrial Property Maintenance",
    "desc": "All type of Industrial Property Maintenance",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/Industrial-maintenance-770x480.jpg.webp",
        "name": "Facility Operations Maintenance",
        "price": 123,
        "description": "Maintenance of heavy equipment and machinery.Industrial HVAC, lighting, and electrical system repairs"
      },
      {
        "id": 2,
        "image": "/image/Industrial-maintenance-770x480.jpg.webp",
        "name": "Warehouse Maintenance",
        "price": 123,
        "description": "Floor cleaning and polishing, spill cleanup, and dust removal .Shelf and storage system maintenance and repair"
      },
      {
        "id": 3,
        "image": "/image/Industrial-maintenance-770x480.jpg.webp",
        "name": "Building Systems Maintenance",
        "price": 123,
        "description": "Regular inspections of fire safety systems, HVAC, and plumbing.Pest control and environmental hazard removal"
      }
    ]
  },
  {
    "id": "Hotel & Hospitality Property Maintenance",
    "img": "image/hotelmaintenaceproperty (1).jpg",
    "name": "Hotel & Hospitality Property Maintenance",
    "desc": "All type of Hotel & Hospitality Property Maintenance",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/hospitaly maintaince.jpg",
        "name": "Guest Room Maintenance",
        "price": 123,
        "description": "Electrical and plumbing repairs in rooms.HVAC and appliance maintenance (air conditioning, mini-fridges)"
      },
      {
        "id": 2,
        "image": "/image/hospitaly maintaince.jpg",
        "name": "Common Area Upkeep",
        "price": 123,
        "description": "Lobby, hallway, and public restroom maintenance and cleaning.Pool and spa area cleaning and equipment repairs"
      }
    ]
  }
]











export const manpowerservices =[
  {
    "id": "Skilled Technicians",
    "img": "image/skilledmanpower (1).jpg",
    "name": "Skilled Technicians",
    "desc": "All type of Skilled Technicians",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/electricaltechnicial (1).jpg",
        "name": "Electrical Technicians",
        "price": 321,
        "description": "Electrical repairs, installations, and maintenance."
      },
      {
        "id": 2,
        "image": "/image/plumbingtechnial.webp",
        "name": "Plumbing Technicians",
        "price": 321,
        "description": "Pipe repairs, installations, and water system maintenance."
      },
      {
        "id": 3,
        "image": "/image/HVAC-Technicians (1).jpg",
        "name": "HVAC Technicians",
        "price": 321,
        "description": "Heating, ventilation, and air conditioning system maintenance."
      },
      {
        "id": 4,
        "image": "/image/appliencereapirtechnian.jpg",
        "name": "Appliance Repair Technicians",
        "price": 321,
        "description": "Maintenance and repair of household and commercial appliances."
      },
      {
        "id": 5,
        "image": "/image/carpentry-work-500x500.webp",
        "name": "Carpenters",
        "price": 321,
        "description": "Custom furniture, cabinetry, and structural repairs."
      }
    ]
  },
  {
    "id": "Cleaning and Janitorial Staff",
    "img": "image/cleaingmanpower.jpg",
    "name": "Cleaning and Janitorial Staff",
    "desc": "All type of Cleaning and Janitorial Staff\n\n",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/residentalmanpower.webp",
        "name": "Residential Cleaners",
        "price": 123,
        "description": "Home cleaning services including deep cleaning, move-in/move-out cleaning."
      },
      {
        "id": 2,
        "image": "/image/officecleaning (1).jpg",
        "name": "Commercial Cleaners",
        "price": 123,
        "description": "Office, retail, and industrial cleaning services."
      },
      {
        "id": 3,
        "image": "/image/janitor (1).jpg",
        "name": "Janitors",
        "price": 123,
        "description": "Routine cleaning and maintenance for buildings, offices, and other facilities."
      },
      {
        "id": 4,
        "image": "/image/spcializesmanpower.jpg",
        "name": "Specialized Cleaners",
        "price": 123,
        "description": "Carpet and upholstery cleaning, window cleaning, pressure washing."
      }
    ]
  },
  {
    "id": "Supervisors and Managers",
    "img": "image/supervisor and manager (1).jpg",
    "name": "Supervisors and Managers",
    "desc": "All type of  Supervisors and Managers",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/sitemanagermanpower (1).jpg",
        "name": "Site Managers",
        "price": 123,
        "description": "Oversee large property maintenance and cleaning projects."
      },
      {
        "id": 2,
        "image": "/image/cleaingmanpower.jpg",
        "name": "Cleaning Supervisors",
        "price": 123,
        "description": "Manage cleaning teams, ensuring compliance with standards."
      },
      {
        "id": 3,
        "image": "/image/maintenacesupervisormanpower (1).jpg",
        "name": "Maintenance Supervisors",
        "price": 123,
        "description": "Maintenance of heavy equipment and machinery.Industrial HVAC, lighting, and electrical system repairs"
      }
    ]
  },
  {
    "id": "Landscaping & Groundskeeping",
    "img": "image/lanscapingstaff (1).jpg",
    "name": " Landscaping & Groundskeeping\n",
    "desc": "All type of  Landscaping & Groundskeeping\n",
    "view": "Click to see details",
    "category": [
      {
        "id": 1,
        "image": "/image/grassekeeper (1).jpg",
        "name": "Landscapers",
        "price": 123,
        "description": "Yard and garden maintenance, tree trimming, and lawn care."
      },
      {
        "id": 2,
        "image": "/image/grassekeeper (1).jpg",
        "name": "Groundskeepers",
        "price": 123,
        "description": "Property exterior upkeep including snow removal and lawn care."
      }
    ]
  }
]














