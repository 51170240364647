import React from 'react';
import WorkHead from './WorkHead';
import {cleanData} from '../Data/Data';
import { NavLink } from 'react-router-dom';

export default function Workrender() {
  return (
    <>
      <div className='mt-24'>
        <h1 className='text-center font-bold text-4xl '>Cleaning</h1>
      </div>

      <div className='flex flex-wrap lg:justify-center max-md:justify-center bg-lime-300 lg:gap-6 mt-8 
      md:p-5 md:gap-8 md:justify-center lg:p-14' >
        {cleanData.map((work, index) => (
          <div key={index} className='flex flex-col items-center w-full sm:w-1/2 md:w-1/2 lg:w-1/4 max-md:px-14 max-md:p-4 max-[320px]:px-5 '>
            <NavLink to={`/work/${work.id}`} className='w-full'>
              <WorkHead
                id={work.id}
                img={work.img}
                desc={work.desc}
                view={work.view}
                name={work.name}
              />
            </NavLink>
          </div>
        ))}
      </div>
    

    </>
  );
}
