import React from 'react'
import { Helmet } from 'react-helmet';
import Servives from '../Services/Servives'
import Slider from '../Slider/Slider'
import ViewDirectService from './ViewDirectService'
import Testimonial from '../Testimonial/Testimonial'
import Contact from '../Contact/Contact'
import AutoSlider from '../Slider/Slider'
import Video from './vedio';


export default function Home() {
  return (
    <>
     <Helmet>
        <title>Home -Maintenance & Cleaning Services</title>
        <meta name="description" content="Welcome to ShaniQ - Your reliable solution for maintenance, cleaning, and manpower services. Discover our range of services." />
      </Helmet>
     <AutoSlider />
     <Servives />
     <ViewDirectService />
     <Video />
     <Testimonial />
     <Contact />
    </>
  )
}
