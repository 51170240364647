import React from 'react';
import { useParams } from 'react-router-dom';
import { manpowerservices } from '../../Componet/Data/Data';
import CleaningCatagory from '../Catagory/CleaningCatagory';

export default function Manpowercatogoryrender(){
  const { manpowerId } = useParams();
  const manpower = manpowerservices.find((item) => item.id === manpowerId);

  if (!manpower) {
    return <div>repair not found</div>;
  }
  if (!manpower.category) {
    return <div>No categories available</div>;
  }
  return (
   
             <div className='flex  w-full  flex-wrap  gap-2 justify-center  mt-28 max-md:mt-20 max-sm:justify-center max-sm:py-2 
              '>
      {manpower.category.map((cat) => (
        <CleaningCatagory
          key={cat.id}
          image={cat.image}
          name={cat.name}
          price={cat.price}
          description={cat.description}
        />
      ))}
    </div>
  );
}



