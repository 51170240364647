import React, { useState, useEffect } from 'react';
import img from '../../images/logo.png';
import { NavLink, Outlet } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import img2 from '../../images/whatsup .png';
import Contactinfo from '../Contactinfo/Contactinfo';
import Aboutus from '../Aboutus';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const showDropdown = () => {
    setIsDropdownOpen(true);
  };

  const hideDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`bg-lime-600  fixed top-0 left-0 right-0 z-50 -mt-9 transition-all duration-300 ease-in-out ${isScrolled ? 'shadow-lg' : ''}`}>
        <div className="flex items-center justify-between lg:justify-start lg:mx-10 px-4 py-2 ">
          <img className="h-14 mt-6 ml-7 max-md:ml-3 max-md:mt-7  lg:h-20 object-fill " src={img} alt="Logo" />
          <NavLink to="/">
            <h1 className="md:hidden  -ml-8 mt-5  text-white font-bold text-4xl ">ShaniQ</h1>
          </NavLink>
          <div className="md:hidden  flex flex-col justify-end cursor-pointer space-y-2 mt-8" onClick={toggleMenu}>
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
          </div>
          <ul className={`lg:flex lg:ml-[1100px] lg:mt-6 lg:space-x-6 md:flex   md:mr-80 md:space-x-5 md:w-1 md:mt-5 md:justify-center md:items-center absolute md:static top-full left-0 bg-lime-400 md:bg-transparent max-md:p-3 transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} right-0`}>
            <NavLink to="/" onClick={() => setIsOpen(false)}>
              <li className="text-white font-bold text-xl hover:text-gray-300 max-md:mt-4 ">Home</li>
            </NavLink>
            <div 
              className="relative"
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <button className="text-white font-bold text-xl hover:text-gray-300 max-md:mt-3 ">Services</button>
              {isDropdownOpen && (
                <ul className="absolute left-0  bg-lime-600 max-md:bg-lime-400  w-60 p-2 rounded-md text-white">
                  <NavLink to="/cleaing">
                    <li className="hover:bg-red-400 p-2 text-xl font-bold">Cleaning</li>
                  </NavLink>
                  <NavLink to="/repairing">
                    <li className="hover:bg-red-400 p-2 text-xl font-bold">Maintenance</li>
                  </NavLink>
                  <NavLink to="/manpower">
                    <li className="hover:bg-red-400 p-2 text-xl font-bold">Manpower Supply </li>
                  </NavLink>
                </ul>
              )}
            </div>
            <NavLink to="/about" onClick={() => setIsOpen(false)}>
              <li className="text-white font-bold text-xl hover:text-gray-300 max-md:mt-3 md:w-24 ">About Us</li>
            </NavLink>

            <NavLink to="https://forms.gle/6WYmgqxk7TWfyxAn8" target='_blank' onClick={() => setIsOpen(false)}>
              <li className="text-white font-bold text-xl hover:text-gray-300 max-md:mt-3">Careers</li>
            </NavLink>
            <li className="text-white font-bold text-xl hover:text-gray-300 lg:hidden mt-4 ">
              <a className="rounded-xl border-2 bg-green-700 px-3 md:hidden" href="tel:+91 8908042477">Call Now</a>
            </li>
          </ul>
        </div>
      </nav>
      <div className='flex mt-36 absolute bg-red-600'>
        <ReactWhatsapp number='91 7848073877' message='I need your services' className='md:hidden'>
          <img className='h-12 w-12 fixed right-1 bottom-1 z-50' src={img2} alt="WhatsApp" />
        </ReactWhatsapp>
      </div>
   
      <Outlet />
    </>
  );
}
